<!-- 我的优惠券--已过期 -->
<template>
  <div class="browseLesson">
    <!-- <p class="myclass-title">
      <span class="title-text">已过期</span>
      <span class="title-border"></span>
    </p> -->
    <headers ref="headers" :headname="headName"></headers>
    <div v-show="count>0" class="coupon_brw">
      <div v-for="(item,index) in resultData.result" :key="index">
        <div class="coupon_item">
          <div v-if="parseFloat(item.discount_radio)" class="money_text">
            <span class="money_text_tow">{{ item.discount_radio_name }}</span>
            <p class="money_text_three">{{ item.left_name }}</p>
          </div>
          <div v-else class="money_text">
            <span class="money_text_one">¥</span>
            <span class="money_text_tow">{{ parseInt(item.money) }}</span>
            <p class="money_text_three">{{ item.left_name }}</p>
          </div>
          <p class="cou_ticket1">
            <span class="lesson_name">{{ item.right_name }}</span>
            <!-- <span class="period_text">有效期至</span>
            <span class="period_text2">{{ item.end_time }}</span> -->
          </p>

          <div class="cou_ticket2">
            <div class="coupon_name">
              <p>
                <span v-if="item.expire_hours" class="coupon_name_black">· 使用有效期至:{{ item.end_time }} </span>
                <span v-else class="coupon_name_black">· 可使用时间：{{ item.start_time+ '-' + item.end_time }} </span>
              </p>
              <!-- <p>
                <span class="coupon_name_black">· </span>
                <span class="coupon_name_red">{{ item.send_username }}</span>
                <span class="coupon_name_black">送给您的课程优惠券 </span>
              </p> -->
              <p v-show="item.type_show">
                <span class="coupon_name_black">· 仅限{{ item.type_name }}使用</span>
              </p>
              <p>
                <span class="coupon_name_black">· 多张课程专享券{{ item.superposition == 0 ? '不能' : '能' }}叠加使用 </span>
              </p>
            </div>

            <div class="coupon_btn">
              <span class="go_use">已过期</span>
            </div>

          </div>
        </div>

      </div>
    </div>
    <!-- <div v-show="isShowEmpty" class="noDataShow">
      <img src="@/assets/image/kong_yhj.png" alt="">
      <p>您还没有已过期优惠券~</p>
    </div> -->
    <noData v-show="isShowEmpty" ref="noData" :msg="msg" :img="img"></noData>
    <el-pagination
      v-show="count>10"
      class="page"
      background
      :total="count"
      layout="prev, pager, next"
      :page-size="queryMsg.limit"
      @current-change="handleCurrentChange"
    ></el-pagination>

  </div>
</template>

<script>
import { couponExpires } from '@/api/coupon.js'
import headers from '@/components/topTitle/index.vue'
import noData from '@/components/noDataBox/index.vue'
export default {
  name: '',
  components: { headers, noData },

  data() {
    return {
      headName: '已过期',
      msg: '您还没有已过期优惠券',
      img: require('@/assets/image/kong_yhj.png'),
      isShowEmpty: false,
      resultData: {
        errNo: 0,
        msg: 'success',
        result: []
      },
      queryMsg: {
        page: 1,
        limit: 10
      },
      list: ['222', '222', '4554', 'wfawf', 'awfwafwaf'],
      count: 10
    }
  },

  computed: {},

  created() {
    this.getData()
  },

  mounted() {

  },

  methods: {
    getData() {
      couponExpires(this.queryMsg).then((res) => {
        if (res.errNo === 0) {
          this.resultData.result = res.result.list
          this.count = res.result.count
          if (this.resultData.result.length === 0) {
            this.isShowEmpty = true
          } else {
            this.isShowEmpty = false
            this.resultData.result.forEach(item => {
              switch (item.type_id) {
                case 1:
                  item.type_name = '老用户'
                  item.type_show = true
                  break
                case 2:
                  item.type_name = '社群好友'
                  item.type_show = true
                  break
                case 10:
                  item.type_show = false
                  break
                case 11:
                  item.type_name = 'V会员'
                  item.type_show = true
                  break
                case 12:
                  item.type_name = 'E会员'
                  item.type_show = true
                  break
                default:
                  item.type_show = false
                  break
              }
            })
          }
        }
      })
    },
    // 分页
    handleCurrentChange(val) {
      this.queryMsg.page = val
      this.getData()
    }
    // collect(lesson_id) {
    //   const info = {
    //     lesson_id: lesson_id
    //   }
    //   editCollect(info).then(res => {
    //     this.$message({
    //       message: res.result,
    //       type: 'success'
    //     })
    //     // this.cancle = false
    //     this.getList()
    //   }).catch(err => {
    //     console.log(err)
    //   })
    // }
  }
}

</script>
<style lang='scss' scoped>
    .browseLesson {
        width: 965px;
        padding: 0 0 20px 0;
        min-height: 900px;
        position: relative;
        font-family: 'PingFangSC-Regular';
    }

    .myclass-title {
        padding: 26px 60px 12px 20px;
        width: 830px;
        font-size: 19px;
        position: relative;
        text-align: left;
    }

    .sanjiao {
        padding: 0 0 0 55px;

    }

    .coupon_count {
        font-size: 12px;
        position: absolute;
        right: 12px;
        color: #fff;
    }

    .coupon_name {
        text-align: left;
        position: absolute;
        margin-left: 5px;
        margin-top: 6px;
        font-size: 12px;
        line-height: 24px;
        p{
          margin-bottom: 0px;
        }
    }

    .coupon_name_red {
        color: #999999;
    }

    .coupon_name_black {
        color: #999999;
    }

    .coupon_btn {
        float: right;
        position: relative;
        width: 150px;
        margin-top: 40px;
        margin-right: 20px;
    }

    .period {
        height: 0px;
    }

    .period_title {
        height: 40px;
    }

    .money_text {
        padding-top: 8px;
        width: 148px;
        position: absolute;

      .money_text_one {
        font-size: 15px;
        color: #fff;
        text-align: center;
        position: relative;
        z-index: 66;
      }

      .money_text_tow {
        font-size: 30px;
        color: #fff;
        padding-left: 5px;
        position: relative;
        z-index: 66;
      }
    }

    .money_text_three {
        position: relative;
        color: #fff;
        z-index: 66;
        top: -2px;
    }
    .period_text {
        font-size: 14px;
        color: #999999;
        float: left;
        padding-left: 120px;
        padding-top: 5px;
        position: relative;
        // font-weight: 700;
    }

    .period_text2 {
        font-size: 14px;
        color: #999999;
        float: left;
        // font-weight: 700;
        padding-left: 20px;
        padding-top: 5px;
        position: relative;
    }

    .lesson_name {
        float: left;
        position: relative;
        width: 200px;
        text-align: left;
        // font-weight: 700;
        margin-left: 160px;
        color: #666;
        padding-top: 5px;
        font-size: 17px;
        overflow: hidden;
        word-break: break-all; //强行换行,将截断英文单词
        text-overflow: ellipsis; //当元素内文本溢出时（超过width部分）显示省略标记（...）
        white-space: nowrap; //文本不会换行，文本会在在同一行上继续，直到遇到 <br> 标签为止

    }

    .cou_ticket2 {
        height: 91px;
        background: #f7f7f7;
    }

    .coupon_brw {
        display: flex;
        flex-wrap: wrap;
    }

    .coupon_item {
        width: 427px;
        // height: 145px;
        margin-left: 30px;
        margin-bottom: 20px;
        margin-top: 20px;
        position: relative;
        border: 1px solid #dfdfdf;
        border-radius: 11px;
        box-shadow: #dfdfdf 0px 0px 10px 0px;
    }

    .cou_ticket1 {
        position: relative;
        height: 78px;
        width: 430px;
        // background: url(https://newoss.zhulong.com/forum/202205/23/11/1653275667342613.png) no-repeat;
        background: url(https://newoss.zhulong.com/forum/202205/23/14/1653288969191926.png) no-repeat;
    }

    .title-border {
        position: absolute;
        left: 10px;
        bottom: 15px;
        width: 4px;
        height: 20px;
        z-index: 2;
        background: #f04e4f;
    }

    .colect {
        width: 908px;
        height: 165px;
        border-bottom: 1px solid #F1F1F1;
        margin-bottom: 20px;
        padding: 24px 0;
    }

    .colect img {
        width: 157px;
        height: 120px;
        float: left;
    }

    .colect .colect-card-text {
        float: left;
        height: 120px;
        width: 680px;
        margin-left: 20px;
        position: relative;
        color: #333333;
        text-align: left;
    }

    .colect .colect-card-text .title {
        font-size: 16px;
        color: #333333;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
    }

    .collect-opt {
        width: 90px;
        height: 30px;
        background: #ee2e2e;
        text-align: center;
        line-height: 30px;
        color: #FFFFFF;
        display: inline-block;
        border-radius: 5px;
        cursor: pointer;
    }

    .cancelCollect {
        width: 90px;
        height: 30px;
        color: #ee2e2e;
        border: 1px solid #ee2e2e;
        text-align: center;
        line-height: 30px;
        background-color: #FFFFFF;
        display: inline-block;
        border-radius: 5px;
        cursor: pointer;
        margin-left: 5px;
    }

    .give_friend {
        float: left;
        width: 66px;
        height: 25px;
        border: 1px solid #ee2e2e;
        border-radius: 15px;
        text-align: center;
        line-height: 25px;
        color: #ee2e2e;
        cursor: pointer;
    }

    .go_use {
        float: right;
        width: 66px;
        border: 1px solid #E4E4E4;
        border-radius: 15px;
        text-align: center;
        line-height: 25px;
        background-color: #999999;
        color: #fff;
        cursor: pointer;
    }

    .clear {
        border: 1px solid #ee2e2e;
        width: 100px;
        border-radius: 15px;
        margin: 0 auto;
        height: 25px;
        line-height: 25px;
        color: #ee2e2e;
        cursor: pointer;
    }

    .page {
        padding: 20px 0;
        margin: 0 auto;
    }

    .noDataShow{
      position: absolute;
      width: 180px;
      height: 180px;
      top: 50%;
      left: 50%;
      margin-top: -90px;
    }
    .noDataShow img {
      display: block;
      margin: auto;
    }
    .noDataShow p {
      text-align: center;
      font-size: 14px;
      color: #999999;
      margin-top: 30px;
    }

    // .noDataBox{
    //   position: relative;
    //   img{
    //     display: block;
    //     margin: 50px auto 0;
    //   }
    //   .noData{
    //     font-size: 30px
    //   }
    // }
</style>
